export const Gear = ({ fill = "#494b74" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="39"
            height="39"
            viewBox="0 0 39 39"
        >
            <g id="vuesax_bulk_setting-2" transform="translate(-300 -188)">
                <g id="setting-2" transform="translate(300 188)">
                    <path
                        id="Vector"
                        d="M0,18.518V15.36a3.378,3.378,0,0,1,3.324-3.41c3.167,0,4.462-2.3,2.869-5.115A3.456,3.456,0,0,1,7.418,2.187L10.445.411a2.88,2.88,0,0,1,3.989,1.077l.192.341c1.575,2.817,4.164,2.817,5.756,0l.192-.341A2.88,2.88,0,0,1,24.565.411l3.027,1.777a3.456,3.456,0,0,1,1.225,4.648c-1.592,2.817-.3,5.115,2.869,5.115a3.378,3.378,0,0,1,3.324,3.41v3.158a3.378,3.378,0,0,1-3.324,3.41c-3.167,0-4.462,2.3-2.869,5.115a3.452,3.452,0,0,1-1.225,4.648l-3.027,1.777a2.88,2.88,0,0,1-3.989-1.077l-.192-.341c-1.575-2.817-4.164-2.817-5.756,0l-.192.341a2.88,2.88,0,0,1-3.989,1.077L7.418,31.69a3.456,3.456,0,0,1-1.225-4.648c1.592-2.817.3-5.115-2.869-5.115A3.378,3.378,0,0,1,0,18.518Z"
                        transform="translate(2 2.561)"
                        fill={fill}
                        opacity="0.4"
                    />
                    <path
                        id="Vector-2"
                        d="M6.5,3.25A3.25,3.25,0,1,1,3.25,0,3.25,3.25,0,0,1,6.5,3.25Z"
                        transform="translate(16.25 16.25)"
                        fill={fill}
                    />
                    <path
                        id="Vector-3"
                        d="M0,0H39V39H0Z"
                        fill="none"
                        opacity="0"
                    />
                </g>
            </g>
        </svg>
    )
}

import { getConfig } from "lib"

// Settings End-Points
// TODO: Change module name after fix from backend-devs
const scriptingConfig = (action) => getConfig({ module: "Settings", action })

// Get Modules
export const getModulesConfig = () => ({
    url: `/api/v1.0/admin/scripting/automodule`,
    config: scriptingConfig("View"),
});

// Run Hooks
export const runHooksConfig = () => ({
    url: `/api/v1.0/admin/scripting/runhooks`,
    method: "POST",
    config: scriptingConfig("Create"),
});

// Create ModuleTemp
export const createModuleTempConfig = () => ({
    url: `/api/v1.0/admin/scripting/createtemp`,
    method: "POST",
    config: scriptingConfig("Create"),
});

// Update ModuleTemp
export const updateModuleTempConfig = () => ({
    url: `/api/v1.0/admin/scripting/updatetemp`,
    method: "PUT",
    config: scriptingConfig("Update"),
});

// Delete ModuleTemp
export const deleteModuleTempConfig = (id) => ({
    url: `/api/v1.0/admin/scripting/deletetemp/${id}`,
    method: "DELETE",
    config: scriptingConfig("Remove"),
});

// Get ModuleTemps
export const getModuleTempsConfig = () => ({
    url: `/api/v1.0/admin/scripting/listtemps`,
    config: scriptingConfig("View"),
});




import { List as $List } from "antd"
import "./List.styles.scss"

const { Item } = $List

export const List = ({ data, renderFn, header, grid, pageSize, loading, onChange, total }) => {
    return (
        <div className="custom-list">
            <$List
                grid={grid}
                header={header}
                dataSource={data}
                loading={loading}
                pagination={{
                    pageSize: pageSize || 8,
					total: total,
                    hideOnSinglePage: true,
                    showSizeChanger: false,
					onChange: onChange,
                    position: "bottom",
                }}
                renderItem={(item) => <Item>{renderFn(item)}</Item>}
            />
        </div>
    )
}

import React, { useState, useEffect } from "react"
import { SimpleModal } from "components"
import { useDispatch, useSelector } from "react-redux"
// import { onLoginDispatch } from 'store/Slices/authSlice';
import { Checkbox } from "antd"
// import { setReceiveOrdersLoading } from 'store/Slices/dashboard';
import { toast } from "react-toastify"
import {
    GetAssignedConfig,
    UpdateAssignedConfig,
    axios,
    canTakeConfig,
    getError,
} from "lib"

export function DefaultPopup({ show, setShow }) {
    const [checkedDepartments, setCheckedDeparments] = useState([])
    const [loading, setLoading] = useState(false)
    const [canTake, setCanTake] = useState(false)
    const dispatch = useDispatch()
    const { user } = useSelector((state) => state?.auth)
    const handleClose = () => {
        setShow(false)
    }
    useEffect(() => {
        if (show) getDeps()
    }, [show, user])

    async function getDeps() {
        setLoading(true)
        try {
            const { config, url } = GetAssignedConfig(user?.id)
            const res = await axios.get(url, config)
            setCheckedDeparments(res?.data?.data?.departments ?? [])
            setCanTake(res?.data?.data?.acceptOrders ?? false)
        } catch (error) {}
        setLoading(false)
    }

    async function submit() {
        setLoading(true)
        try {
            const { config, url } = UpdateAssignedConfig()
            await axios.put(url, checkedDepartments, config)
            await updateUser()
            setShow(false)
        } catch (e) {
            toast.error(getError(e))
        }
        setLoading(false)
    }

    async function updateUser() {
        const { config, url } = canTakeConfig(canTake)
        await axios.put(url, {}, config)
    }

    return (
        <SimpleModal
            show={show}
            loading={loading}
            setShow={setShow}
            onHide={handleClose}
            heading="Assigning Tickets to Admin"
            cancelButtonText="Go Back"
            submitText="Update Active Groups"
            disabled={loading}
            handleSubmit={submit}
            className="text-center justify-center"
        >
            <div className="text-center flex flex-col ">
                <h5 className="text-[16px] text-white mb-[20px]">{`Check the boxes below to start receiving Tickets from the Departments`}</h5>
                {
                    <div className="text-white text-left">
                        {checkedDepartments?.map((department) => {
                            return (
                                <p>
                                    <Checkbox
                                        checked={department.checked}
                                        onChange={(e) => {
                                            setCheckedDeparments((prev) => {
                                                const newArr = [...prev]
                                                const index = newArr.findIndex(
                                                    (item) =>
                                                        item.id ===
                                                        department.id
                                                )
                                                newArr[index].checked =
                                                    e.target.checked
                                                return newArr
                                            })
                                        }}
                                        className="text-white text-left"
                                    >
                                        {` ${department?.name} `}
                                    </Checkbox>
                                </p>
                            )
                        })}
                    </div>
                }
                <div>
                    <h5 className="text-[16px] text-left text-white mt-[20px] mb-[5px]">{`Check the box below to start receiving Orders`}</h5>
                    <div className="text-white text-left">
                        <Checkbox
                            checked={canTake}
                            onChange={(x) => setCanTake(x.target.checked)}
                            className="text-white text-left"
                        >
                            Receive Orders
                        </Checkbox>
                    </div>
                </div>
            </div>
        </SimpleModal>
    )
}

export const Mail = ({ fill = "#494b74" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="39"
            height="39"
            viewBox="0 0 39 39"
        >
            <g
                id="vuesax_bulk_sms"
                data-name="vuesax/bulk/sms"
                transform="translate(-556 -252)"
            >
                <g id="sms" transform="translate(556 252)">
                    <path
                        id="Vector"
                        d="M0,0H39V39H0Z"
                        fill="none"
                        opacity="0"
                    />
                    <path
                        id="Vector-2"
                        data-name="Vector"
                        d="M24.375,27.625H8.125C3.25,27.625,0,25.188,0,19.5V8.125C0,2.438,3.25,0,8.125,0h16.25C29.25,0,32.5,2.438,32.5,8.125V19.5C32.5,25.188,29.25,27.625,24.375,27.625Z"
                        transform="translate(3.25 5.688)"
                        fill={fill}
                        opacity="0.4"
                    />
                    <g id="Group" transform="translate(10.152 13.397)">
                        <path
                            id="Vector-3"
                            data-name="Vector"
                            d="M9.348,7.517a6.041,6.041,0,0,1-3.8-1.284L.459,2.171A1.214,1.214,0,1,1,1.971.269L7.057,4.332a3.877,3.877,0,0,0,4.566,0L16.709.269a1.2,1.2,0,0,1,1.706.195,1.2,1.2,0,0,1-.195,1.706L13.134,6.233A5.964,5.964,0,0,1,9.348,7.517Z"
                            transform="translate(0 0)"
                            fill={fill}
                        />
                    </g>
                </g>
            </g>
        </svg>
    )
}

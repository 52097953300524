export const Copy = () => {
    return (
        <svg
            id="vuesax_bulk_copy"
            // data-name="vuesax/bulk/copy"
            xmlns="http://www.w3.org/2000/svg"
            width="17.922"
            height="24"
            viewBox="0 0 17.922 24"
        >
            <g id="copy">
                <path
                    id="Vector"
                    d="M10.455,4.9V9.1c0,3.5-1.045,4.9-3.659,4.9H3.659C1.045,14,0,12.6,0,9.1V4.9C0,1.4,1.045,0,3.659,0H6.8C9.409,0,10.455,1.4,10.455,4.9Z"
                    transform="translate(1.494 8)"
                    fill="#494b74"
                />
                <path
                    id="Vector-2"
                    // data-name="Vector"
                    d="M6.788,0H3.652C1.075,0,.03,1.37,0,4.75H2.307C5.444,4.75,6.9,6.7,6.9,10.9v3.09c2.524-.04,3.547-1.44,3.547-4.89V4.9C10.447,1.4,9.4,0,6.788,0Z"
                    transform="translate(5.982 2)"
                    fill="#494b74"
                    opacity="0.4"
                />
                <path
                    id="Vector-3"
                    // data-name="Vector"
                    d="M0,0H17.922V24H0Z"
                    fill="none"
                    opacity="0"
                />
            </g>
        </svg>
    )
}

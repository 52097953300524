export const Next = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <g id="arrow-right" transform="translate(-620 -188)">
                <path
                    id="Vector"
                    d="M0,0,6.07,6.07,0,12.14"
                    transform="translate(634.43 193.93)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    id="Vector-2"
                    data-name="Vector"
                    d="M0,0H16.83"
                    transform="translate(623.5 200)"
                    fill="none"
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.5"
                />
                <path
                    id="Vector-3"
                    data-name="Vector"
                    d="M0,0H24V24H0Z"
                    transform="translate(644 212) rotate(180)"
                    fill="none"
                    opacity="0"
                />
            </g>
        </svg>
    )
}

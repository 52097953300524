export const Search = () => {
    return (
        <svg
            id="vuesax_bulk_search-normal"
            // data-name="vuesax/bulk/search-normal"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <g id="search-normal">
                <path
                    id="Vector"
                    d="M19,9.5A9.5,9.5,0,1,1,9.5,0,9.5,9.5,0,0,1,19,9.5Z"
                    transform="translate(2 2)"
                    fill="#3699ff"
                    opacity="0.4"
                />
                <path
                    id="Vector-2"
                    // data-name="Vector"
                    d="M2.552,3.252a.7.7,0,0,1-.49-.2L.2,1.192A.706.706,0,0,1,.2.2a.706.706,0,0,1,.99,0l1.86,1.86a.706.706,0,0,1,0,.99A.738.738,0,0,1,2.552,3.252Z"
                    transform="translate(18.747 18.747)"
                    fill="#3699ff"
                />
                <path
                    id="Vector-3"
                    // data-name="Vector"
                    d="M0,0H24V24H0Z"
                    fill="none"
                    opacity="0"
                />
            </g>
        </svg>
    )
}

import {
  addPaymentGatewayConfig,
  axios,
  editPaymentGatewayConfig,
  getError,
  getPaymentGatewaysConfig,
  getUserCardsConfig,
  listPaymentGatewaysConfig,
  addCardConfig,
  payConfig,
  getTransfersConfig,
  getUserTransfersConfig,
  approveTransferConfig,
  getPayUrlConfig,
  getRefundConfig,
  cancelAgreeConfig
} from 'lib';
import { toast } from 'react-toastify';
import { getPaymentGateways, setGateways, setPaymentGatewaysLoading, setUserCards, setTransfers, setUserTransfers } from 'store/Slices';
import { getCreditBalanceByClient } from './creditsActions';
import { getUserById } from './usersActions';
import { getInvoiceById } from "store/Actions"

export const getAllPaymentGateways = () => {
  return async (dispatch) => {
    dispatch(setPaymentGatewaysLoading(true));
    try {
      const { url, config } = getPaymentGatewaysConfig();
      const response = await axios.get(url, config);
      dispatch(getPaymentGateways(response?.data?.data));
    } catch (error) {
      console.error(error)
      toast.error(getError(error));
    } finally {
      dispatch(setPaymentGatewaysLoading(false));
    }
  };
};

export const makePayment = (id, data) =>{
    return async (dispatch) => {
      dispatch(setPaymentGatewaysLoading(true));
      try {
        const { url, config } = payConfig(id);
        const response = await axios.post(url, data, config);
        if (response.status === 200) {
          toast.success('Payment Successful');
          dispatch(getCreditBalanceByClient(id))
        }
      } catch (error) {
        toast.error(getError(error));
      } finally {
        dispatch(setPaymentGatewaysLoading(false));
      }
    };
}

export const addCard = (data) => {
  return async (dispatch) => {
    dispatch(setPaymentGatewaysLoading(true));
    try {
      const { url, config } = addCardConfig();
      const response = await axios.post(url, data, config);
      if (response.status === 200) {
        toast.success('Card Added Successfully');
        dispatch(getUserCards(data.userId))
      }
    } catch (error) {
      toast.error(getError(error));
    } finally {
      dispatch(setPaymentGatewaysLoading(false));
    }
  };
}

export const listAllPaymentGateways = () => {
  return async (dispatch) => {
    dispatch(setPaymentGatewaysLoading(true));
    try {
      const { url, config } = listPaymentGatewaysConfig();
      const response = await axios.get(url, config);
      dispatch(setGateways(response?.data?.data));
    } catch (error) {
      toast.error(getError(error));
    } finally {
      dispatch(setPaymentGatewaysLoading(false));
    }
  };
};

export const getUserCards = (id) => {
  return async (dispatch) => {
    dispatch(setPaymentGatewaysLoading(true));
    try {
      const { url, config } = getUserCardsConfig(id);
      const response = await axios.get(url, config);
      dispatch(setUserCards(response?.data?.data));
    } catch (error) {
      toast.error(getError(error));
    } finally {
      dispatch(setPaymentGatewaysLoading(false));
    }
  };
}

export const addPaymentGateway = ({ data }) => {
    return async (dispatch) => {
        dispatch(setPaymentGatewaysLoading(true))
        try {
            const { url, config } = addPaymentGatewayConfig()
            const response = await axios.post(url, data, config)
            if (response.status === 200) {
                const { url, defaultData, config } = getPaymentGatewaysConfig()
                const response = await axios.post(url, defaultData, config)
                dispatch(getPaymentGateways(response?.data?.data))
                toast.success("Payment Gateway Added Successfully")
            }
        } catch (error) {
            toast.error(getError(error))
        } finally {
            dispatch(setPaymentGatewaysLoading(false))
        }
    }
}

export const editPaymentGateway = (id, data) => {
  return async (dispatch) => {
    dispatch(setPaymentGatewaysLoading(true));
    try {
      const { url, config } = editPaymentGatewayConfig(id);
      const response = await axios.put(url, data, config);
      if (response.status === 200) {
        dispatch(getAllPaymentGateways());
        toast.success('Payment Gateway Updated Successfully');
      }
    } catch (error) {
      toast.error(getError(error));
    } finally {
      dispatch(setPaymentGatewaysLoading(false));
    }
}
}

export const getTransfers = () => {
  return async (dispatch) => {
    dispatch(setPaymentGatewaysLoading(true));
    try {
      const { url, config } = getTransfersConfig();
      const response = await axios.get(url, config);
      dispatch(setTransfers(response?.data?.data));
    } catch (error) {
      toast.error(getError(error));
    } finally {
      dispatch(setPaymentGatewaysLoading(false));
    }
  };
};

export const getUserTransfers = (id) => {
  return async (dispatch) => {
    dispatch(setPaymentGatewaysLoading(true));
    try {
      const { url, config } = getUserTransfersConfig(id);
      const response = await axios.get(url, config);
      dispatch(setUserTransfers(response?.data?.data));
    } catch (error) {
      toast.error(getError(error));
    } finally {
      dispatch(setPaymentGatewaysLoading(false));
    }
  };
};

export const approveTransfer = (id) => {
  return async (dispatch) => {
    dispatch(setPaymentGatewaysLoading(true));
    try {
      const { url, config } = approveTransferConfig(id);
      const response = await axios.post(url, {}, config);
      if (response.status === 200) {
        dispatch(getTransfers());
        toast.success('Transfer Approved Successfully');
      }
    } catch (error) {
      toast.error(getError(error));
    } finally {
      dispatch(setPaymentGatewaysLoading(false));
    }
  };
};

export const makeRefund = (data) => {
  return async (dispatch) => {
    dispatch(setPaymentGatewaysLoading(true));
    try {
      const { url, config } = getRefundConfig();
      const response = await axios.post(url, data, config);
      if (response.status === 200) {
        toast.success('Refund Successful');
      }
	  dispatch(getInvoiceById(data.invoiceId))
    } catch (error) {
      toast.error(getError(error));
    } finally {
      dispatch(setPaymentGatewaysLoading(false));
    }
  };
};

export const getPayUrl = async (data, dispatch) => {
    dispatch(setPaymentGatewaysLoading(true));
    try {
      const { url, config } = getPayUrlConfig();
      const response = await axios.post(url, data, config);
      if (response.status === 200) {
        toast.success('Payment Url Generated Successfully');
        return response?.data?.data;
      }
    } catch (error) {
      toast.error(getError(error));
    } finally {
      dispatch(setPaymentGatewaysLoading(false));
    }
};

export const cancelAgree = (userId) => {
  return async (dispatch) => {
    dispatch(setPaymentGatewaysLoading(true));
    try {
      const { url, config } = cancelAgreeConfig();
      const response = await axios.post(url, {userId}, config);
      if (response.status === 200) {
        toast.success('Agreement Cancelled Successfully');
		dispatch(getUserById(userId, true))
      }
    } catch (error) {
      toast.error(getError(error));
    } finally {
      dispatch(setPaymentGatewaysLoading(false));
    }
  };
};
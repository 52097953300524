export const Knowledge = ({ fill = "#494b74" }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
        >
            <g
                id="vuesax_bulk_teacher"
                // data-name="vuesax/bulk/teacher"
                transform="translate(-172 -252)"
            >
                <g id="teacher">
                    <path
                        id="Vector"
                        d="M12.76,0V4.93a3.427,3.427,0,0,1-2.18,3.03L7.39,9.02a3.651,3.651,0,0,1-2.02,0L2.18,7.96A3.411,3.411,0,0,1,0,4.93L.01,0,4.43,2.88a3.91,3.91,0,0,0,3.94,0Z"
                        transform="translate(177.62 264.84)"
                        fill={fill}
                        opacity="0.4"
                    />
                    <path
                        id="Vector-2"
                        // data-name="Vector"
                        d="M17.4,4.463,11.407.532a3.91,3.91,0,0,0-3.94,0l-6.02,3.93a3.186,3.186,0,0,0,0,5.34l1.6,1.04,4.42,2.88a3.91,3.91,0,0,0,3.94,0l4.39-2.88,1.37-.9V13a.75.75,0,0,0,1.5,0V8.082A3.175,3.175,0,0,0,17.4,4.463Z"
                        transform="translate(174.583 253.997)"
                        fill={fill}
                    />
                    <path
                        id="Vector-3"
                        // data-name="Vector"
                        d="M0,0H24V24H0Z"
                        transform="translate(172 252)"
                        fill="none"
                        opacity="0"
                    />
                </g>
            </g>
        </svg>
    )
}

import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    paymentGateways: [],
    gateways: [],
    loading: false,
    userCards: [],
    transfers: [],
    userTransfers: [],
}
const paymentGatewaySlice = createSlice({
    name: "paymentGateways",
    initialState,
    reducers: {
        getPaymentGateways: (state, { payload }) => {
            const dataToSet = payload.map((pg) => {
                const config = JSON.parse(pg.config)

                var intial = {}
                for (const key in config) {
                    intial[key] = config[key].value
                }
                return {
                    ...pg,
                    config: config,
                    intial: intial,
                }
            })
            state.paymentGateways = dataToSet
        },
        setGateways: (state, { payload }) => {
            state.gateways = payload
        },
        setPaymentGatewaysLoading: (state, { payload }) => {
            state.loading = payload
        },
        setUserCards: (state, { payload }) => {
            state.userCards = payload
        },
        setTransfers: (state, { payload }) => {
            state.transfers = payload
        },
        setUserTransfers: (state, { payload }) => {
            state.userTransfers = payload
        },
    },
})

const { reducer, actions } = paymentGatewaySlice

export const {
    getPaymentGateways,
    setPaymentGatewaysLoading,
    setUserCards,
    setGateways,
    setTransfers,
    setUserTransfers,
} = actions
export default reducer

import { getConfig } from "lib"

// ModuleManagement End-Points
const moduleManagement = "ManageModule"

export const getAppModulesConfig = (tenant = "admin") => ({
    url: `/api/modulemanagement/getmodulebytenant/${tenant}`,
    config: getConfig({ module: UserModuleManagement, action: "Get" }),
})

// UserModuleManagement End-Points
const UserModuleManagement = "Admin"
// Get User Modules
export const getUserModulesConfig = (userId) => ({
    url: `/api/usermodulemanagement/getmodulebyuser/${userId}`,
})
// Add User Module
export const addUserModule = () => ({
    url: `/api/usermodulemanagement`,
    config: getConfig({ module: UserModuleManagement, action: "Create" }),
})
// Update User Module (mid = Module ID)
export const updateUserModule = (mid) => ({
    url: `/api/usermodulemanagement/${mid}`,
    config: getConfig({ module: UserModuleManagement, action: "Update" }),
})

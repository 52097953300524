import axios from "axios"
import { useMemo } from "react"
import { EditorState, ContentState, convertFromHTML } from "draft-js"
import { browserName, browserVersion } from "react-device-detect"
import { useLocation } from "react-router-dom"
import moment from "moment"
import * as XLSX from "xlsx"
import * as FileSaver from "file-saver"

export const getGroupModules = ({ appModules = [], groupModules = [] }) => {
    const groupNames = groupModules?.map((el) => el?.name)
    const filteredModules = appModules?.filter((module) => {
        return !groupNames?.includes(module?.name)
    })
    const filteredModulesFalse = filteredModules.map((module) => {
        return {
            ...module,
            id: null,
            permissionDetail: {
                Create: false,
                View: false,
                Update: false,
                Remove: false,
            },
        }
    })
    const parsedGroupPermissions = groupModules.map((module) => {
        return {
            ...module,
            permissionDetail: JSON.parse(module && module?.permissionDetail),
        }
    })
    const newArray = [...filteredModulesFalse, ...parsedGroupPermissions]
    newArray.sort((a, b) => {
        let fa = a.name.toLowerCase(),
            fb = b.name.toLowerCase()
        if (fa < fb) {
            return -1
        }
        if (fa > fb) {
            return 1
        }
        return 0
    })
    return newArray
}

export const getUserModules = ({ appModules = [], userModules = [] }) => {
    const groupNames = userModules?.map((el) => el?.name)
    const filteredModules = appModules?.filter((module) => {
        return !groupNames?.includes(module?.name)
    })
    const filteredModulesFalse = filteredModules.map((module) => {
        return {
            ...module,
            id: null,
            permissionDetail: {
                Create: false,
                View: false,
                Update: false,
                Remove: false,
            },
        }
    })
    const parsedUserModules = userModules.map((module) => {
        return {
            ...module,
            permissionDetail: JSON.parse(module && module?.permissionDetail),
        }
    })
    const newArray = [...filteredModulesFalse, ...parsedUserModules]
    newArray.sort((a, b) => {
        let fa = a.name.toLowerCase(),
            fb = b.name.toLowerCase()
        if (fa < fb) {
            return -1
        }
        if (fa > fb) {
            return 1
        }
        return 0
    })
    return newArray
}

export async function asyncForEach(array, callback) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array)
    }
}

// Convert Image to Base64
export const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const fileReader = new FileReader()
        fileReader.readAsDataURL(file)

        fileReader.onload = () => {
            resolve(fileReader.result)
        }

        fileReader.onerror = (error) => {
            reject(error)
        }
    })
}

// Create Image Data from Input type="image"
export const createServerImage = async (file) => {
    const fileName = file?.name
    const imgData = {}
    if (fileName) {
        const ext = fileName.substr(fileName.lastIndexOf("."))
        const finalName = fileName.substr(0, fileName.indexOf("."))
        let base64image = ""
        try {
            base64image = await convertBase64(file)
            imgData.name = finalName
            imgData.extension = `${ext}`
            // imgData.data = base64image;
            imgData.data = base64image?.split(",")?.[1]
            return imgData
        } catch (e) {
            return undefined
        }
    }
}

// Add Server URL Properly
export const addServerUrl = (
    url = "{server_url}/Files/Images/ApplicationUser/Usama.jpeg.jpeg"
) => {
    const truncated = url.substring(url.indexOf("/"), url.length)
    const finalURL = `${process.env.REACT_APP_BASEURL}${truncated}`
    return finalURL
}

// Convert URL to File
export const convertUrlToFile = async (imgUrl, fileName) => {
    const imgExt = imgUrl.split(/[#?]/)[0].split(".").pop().trim()
    try {
        const response = await fetch(imgUrl)
        const blob = await response.blob()
        const file = new File([blob], `${fileName}.` + imgExt, {
            type: blob.type,
        })
        return file
    } catch (e) {
        console.log(e)
    }
}

// Get Difference between now and date
export const getDifference = (date) => {
    const now = new Date()
    const diff = now.getTime() - date.getTime()

    const diffInMinutes = Math.round(diff / 60000)
    const diffInHours = Math.round(diffInMinutes / 60)
    const diffInDays = Math.round(diffInHours / 24)
    const diffInWeeks = Math.round(diffInDays / 7)
    const diffInMonths = Math.round(diffInDays / 30)
    const diffInYears = Math.round(diffInDays / 365)
    // console.log(date);
    if (diffInMinutes < 1) {
        return "Just Now"
    }
    if (diffInMinutes < 2) {
        return "1 Minute Ago"
    }
    if (diffInMinutes < 60) {
        return `${diffInMinutes} Minutes Ago`
    }
    if (diffInHours < 2) {
        return "1 Hour Ago"
    }
    if (diffInHours < 24) {
        return `${diffInHours} Hours Ago`
    }
    if (diffInDays < 2) {
        return "1 Day Ago"
    }
    if (diffInDays < 7) {
        return `${diffInDays} Days Ago`
    }
    if (diffInWeeks < 2) {
        return "1 Week Ago"
    }
    if (diffInWeeks < 4) {
        return `${diffInWeeks} Weeks Ago`
    }
    if (diffInMonths < 2) {
        return "1 Month Ago"
    }
    if (diffInMonths < 12) {
        return `${diffInMonths} Months Ago`
    }
    if (diffInYears < 2) {
        return "1 Year Ago"
    }
    return `${diffInYears} Years Ago`
}

export const getTimeDiff = (date) => {
    const startTime = moment.utc(date)
    const endTime = moment.utc(new Date())

    // calculate total duration
    const duration = moment.duration(endTime.diff(startTime))

    // duration in days
    const days = Math.floor(duration.asDays())

    // remaining duration after extracting days
    const remainingDuration = duration.subtract(days, "days")

    // duration in hours
    const hours = Math.floor(remainingDuration.asHours())

    // remaining duration after extracting hours
    const remainingDurationAfterHours = remainingDuration.subtract(
        hours,
        "hours"
    )

    // duration in minutes
    const minutes = Math.floor(remainingDurationAfterHours.asMinutes())

    // remaining duration after extracting minutes
    const seconds = Math.floor(
        remainingDurationAfterHours.subtract(minutes, "minutes").asSeconds()
    )

    const newDays = days === 0 ? "" : `${days}d`
    const newHrs = hours === 0 ? "" : `${hours}h`
    const newMins = minutes === 0 ? "" : `${minutes}m`
    const newSeconds = seconds === 0 ? "" : `${seconds}s`

    let timeDiff = newDays + " " + newHrs + " " + newMins + " " + newSeconds

    return timeDiff.trim()
}

export const getIPData = async () => {
    try {
	const res = await fetch("https://geolocation-db.com/json/")
	if(res.status != 200){
		const localData = localStorage.getItem("ipData_admin")
		? JSON.parse(localStorage.getItem("ipData_admin"))
		: {
			ip: "",
			location: "",
		}
		return localData
	}
	res.data = await res.json()
    const { city, state, country_name } = res.data
    const cityString = city ? `${city}, ` : ""
    const stateString = state ? `${state}, ` : ""
    const countryString = country_name ? `${country_name}` : ""
	const data = {
        ip: res.data.IPv4,
        location: `${cityString}${stateString}${countryString}`,
    }
	localStorage.setItem("ipData_admin", JSON.stringify(data))
    return data
	} catch (error) {
		const localData = localStorage.getItem("ipData_admin")
		? JSON.parse(localStorage.getItem("ipData_admin"))
		: {
			ip: "",
			location: "",
		}
		return localData
	}
}

// Get Device Name
export const getDeviceName = () => {
    return `${browserName} ${browserVersion}`
}

// Convert camelCase to Title Case
export const convertCamelToTitle = (str) => {
    const result = str.replace(/([A-Z])/g, " $1")
    const finalResult = result.charAt(0).toUpperCase() + result.slice(1)
    return finalResult
}

// Convert HTML to Draft JS
export const convertHTMLToDraftState = (html) => {
    if (html) {
        const blocks = convertFromHTML(html)
        const state = ContentState.createFromBlockArray(
            blocks?.contentBlocks,
            blocks?.entityMap
        )
        return EditorState.createWithContent(state)
    } else {
        return EditorState.createEmpty()
    }
}

export const statusList = (i = "") => {
    switch (i) {
        case 0:
            return {
                name: "Draft",
                bg: "#392F28",
                text: "#FFA800",
            }
        case 1:
            return {
                name: "Pending",
                bg: "#392F28",
                text: "#FFA800",
            }
        case 2:
            return {
                name: "Paid",
                bg: "#1C3238",
                text: "#0BB783",
            }
        case 3:
            return {
                name: "Processing",
                bg: "#3A2434", // Light blue for processing
                text: "#F64E60", // Dark blue text
            }
        case 4:
            return {
                name: "Accepted",
                bg: "#212E48", // Light cyan for accepted
                text: "#3699FF", // Dark cyan text
            }
        case 5:
            return {
                name: "Completed",
                bg: "#1C3238",
                text: "#0BB783",
            }
        case 6:
            return {
                name: "Cancelled",
                bg: "#3A2434", // Light blue for processing
                text: "#F64E60", // Dark blue text
            }
        default:
            return {
                name: "Unknown",
                bg: "#E2E3E5", // Light grey for unknown status
                text: "#6C757D", // Dark grey text
            }
    }
}

export const orderList = (i = "") => {
    let p = [
        {
            name: "Pending",
            bg: "#392F28",
            text: "#FFA800",
        },
        {
            name: "Active",
            bg: "#1C3238",
            text: "#0BB783",
        },
        {
            name: "Cancelled",
            bg: "#323248",
            text: "#FFFFFF",
        },

        {
            name: "Suspended",
            bg: "#1C3238",
            text: "#0BB783",
        },
    ]
    return i !== "" ? p[i] : p
}

export const orderList1 = (i = "") => {
    let p = [
        {
            name: "Draft",
            value: 0,
        },
        {
            name: "Pending",
            value: 1,
        },
        {
            name: "Paid",
            value: 2,
        },
        {
            name: "Processing",
            value: 3,
        },
        {
            name: "Accepted",
            value: 4,
        },
        {
            name: "Completed",
            value: 5,
        },
        {
            name: "Canceled",
            value: 6,
        },
    ]
    return i !== "" ? p[i] : p
}

export const getNotificationType = ({ type }) => {
    switch (type) {
        case 0:
            return "New User Registered"
        case 1:
            return "New Ticket Created"
        case 2:
            return "Ticket Updated"
        case 3:
            return "New Order Created"
        case 4:
            return "Order Updated"
        case 5:
            return "New Comment Added on Ticket"
        case 6:
            return "New Reply Added on Ticket Comment"
        case 7:
            return "Category Generated"
        case 8:
            return "Bills"
        default:
            return ""
    }
}

export const getNotificationLink = ({ type }) => {
    switch (type) {
        case 0:
            return "/admin/dashboard/billing/clients/list/show"
        case 1:
            return "/admin/dashboard/support/tickets/list"
        case 2:
            return "/admin/dashboard/support/tickets/list"
        case 3:
            return "/admin/dashboard/support/tickets/list"
        case 4:
            return "/admin/dashboard/billing/orders/your-orders/list"
        case 5:
            return "/admin/dashboard/billing/orders/your-orders/list"
        case 6:
            return "/admin/dashboard/billing/orders/your-orders/list"
        case 7:
            return "/admin/dashboard/support/tickets/list"
        case 8:
            return "/admin/dashboard/support/tickets/list"
        case 9:
            return "#"
        case 10:
            return "#"
        case 11:
            return "/admin/dashboard/billing/invoices/list/show"
        case 12:
            return "/admin/dashboard/billing/invoices/list/show"
        case 13:
            return "#"
        case 14:
            return "#"
        case 15:
            return "/admin/dashboard/knowledge-base/feedback"
        case 16:
            return "/admin/dashboard/knowledge-base/feedback"
        case 17:
            return "/admin/dashboard/knowledge-base/feedback"
        case 18:
            return "/admin/dashboard/billing/products-services/list/show"
        default:
            return "#"
    }
}

export const getNotificationTarget = ({ target }) => {
    switch (target) {
        case 0:
            return "Clients"
        case 1:
            return "Admins"
        default:
            return ""
    }
}

export const groupBy = (objectArray, property) => {
    if (objectArray.length > 0) {
        return objectArray.reduce((acc, obj) => {
            const key = obj[property]
            if (!acc[key]) {
                acc[key] = []
            }
            // Add object to list for given key's value
            acc[key].push(obj)
            return acc
        }, {})
    }
}

export const genrateFirstLetterName = (value) => {
    let name = ""
    if (value) {
        let userN = value?.split(" ")
        if (userN.length < 2) {
            name = userN[0].charAt(0)
        } else {
            name = userN[0].charAt(0) + userN[1].charAt(0)
        }
        return name
    }
}

// Export table to excel file
export const exportToExcel = (object) => {
    const fileType = "text/csv;charset=utf-8"
    const fileExtension = ".csv"

    // const json = JSON.stringify(object);
    const ws = XLSX.utils.json_to_sheet(object)

    const wb = {
        Sheets: { data: ws },
        SheetNames: [`data`],
    }

    const excelBuffer = XLSX.write(wb, { bookType: "csv", type: "array" })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(
        data,
        `Report-${moment().format("MM-DD-YYYY [at] HH:mm A")}` + fileExtension
    )
}

// Get Template Variables
export const getTemplateVariables = (templateType) => {
    const variables = {
        EmailConfirmation:
            "[logo], [fullName], [company], [address],[userName],[email],[emailVerificationUri]",
        EmailOTP:
            "[logo], [fullName], [company], [address],[otpcode],[userName]",
        General: "[logo],[userName],[email],[company],[address],[fullname]",
        Invoice: "[logo], [fullName], [company], [address],[invoicelink]",
        Orders: "[logo], [fullName], [company], [address],[orderlink]",
        ProductCancellation:
            "[logo], [fullName], [company], [address],[productlink]",
        ProductStatusUpdated:
            "[logo], [fullName], [company], [address],[productlink]",
        ResetPassword:
            "[logo], [fullName], [company], [address],[userName],[resetPasswordUri]",
        TicketAssignment:
            "[logo],[ticketMessage], [userName], [fullName], [company], [address],[ticketlink]",
        TicketCreated:
            "[logo], [ticketMessage], [fullName], [company], [address],[ticketlink]",
        TicketUpdated:
            "[logo], [ticketMessage], [fullName], [company], [address],[ticketlink]",
        OrderCreated: "[logo], [fullName], [company], [address],[orderlink]",
        OrderAssignment: "[logo], [userName], [address],[orderlink]",
        ProductCreated: "[logo], [userName],, [address],[productlink]",
        ProductAssignment:
            "[logo], [userName], [company], [address],[productlink]",
        ClientPasswordReset:
            "[logo], [fullName], [company], [password], [brandUrl]",
    }
    switch (templateType) {
        case 0:
            return variables?.General
        case 1:
            return variables?.EmailConfirmation
        case 2:
            return variables?.EmailOTP
        case 3:
            return variables?.ProductCancellation
        case 4:
            return variables?.ResetPassword
        case 5:
            return variables?.TicketUpdated
        case 6:
            return variables?.TicketCreated
        case 7:
            return variables?.TicketAssignment
        case 8:
            return variables?.Orders
        case 9:
            return variables?.Invoice
        case 10:
            return variables?.ProductStatusUpdated
        case 11:
            return variables?.OrderCreated
        case 12:
            return variables?.OrderAssignment
        case 13:
            return variables?.ProductCreated
        case 14:
            return variables?.ProductAssignment
        case 15:
            return variables?.ClientPasswordReset
        default:
            return variables?.General
    }
}

// Get Current Date
export const getCurrentDate = () => {
    var d = new Date()
    var datestring =
        d.getDate() + "-" + (d.getMonth() + 1) + "-" + d.getFullYear()
    return datestring
}

// Find client user in a list
export const findClientUser = (id, clients) => {
    return clients?.find((client) => client?.id?.toLowerCase() === id)
}

// Generate random characters
export const generateRandAlphaNumStr = (len) => {
    var rdmString = ""
    for (
        ;
        rdmString.length < len;
        rdmString += Math.random().toString(36).substr(2)
    );
    return rdmString.substr(0, len)
}

// Email template types
export const emailTemplateTypes = () => {
    return [
        "General",
        "Email Confirmation",
        "Email OTP",
        "Product Cancellation",
        "Reset Password",
        "Ticket Update",
        "Ticket Create",
        "Ticket Assignment",
        "Orders",
        "Invoice",
        "Product Status Updated",
        "Order Created",
        "Order Assignment",
        "Product Created",
        "Product Assignment",
        "Client Password Reset",
        "Invoice Due",
        "Invoice Upcoming",
        "Payment Success",
        "Payment Failed",
    ]
}

export function useQuery() {
    const { search } = useLocation()
    return useMemo(() => new URLSearchParams(search), [search])
}

export function getTTL(time) {
    const updated = moment(time)
    const now = moment(new Date())
    const diff_s = updated.diff(now, "seconds")
    return diff_s
}

export const Print = (divId) => {
    //console.log('print');
    let printContents = document.getElementById(divId).innerHTML
    document.body.innerHTML = printContents
    window.print()
    window.location.reload()
}

export const sortCategories = (arr) => {
    let sortedArr = []
    let map = {}

    arr = arr.map((cat) => {
        return {
            id: cat?.id,
            parentCategoryId: cat?.parentCategoryId,
            name: cat?.name,
        }
    })

    arr.forEach((item) => {
        map[item.id] = item
    })

    function dfs(item) {
        sortedArr.push(item)
        let numChildren = 0
        arr.forEach((child) => {
            if (child.parentCategoryId === item.id) {
                numChildren++
                dfs(child)
            }
        })
        if (item.parentCategoryId === "00000000-0000-0000-0000-000000000000") {
            item.numChildren = numChildren
        }
    }

    arr.forEach((item) => {
        if (item.parentCategoryId === "00000000-0000-0000-0000-000000000000") {
            dfs(item)
        }
    })

    return [...sortedArr]
        ?.filter(
            (item) =>
                (item?.parentCategoryId ===
                    "00000000-0000-0000-0000-000000000000" &&
                    item?.numChildren > 0) ||
                item?.parentCategoryId !==
                    "00000000-0000-0000-0000-000000000000"
        )
        ?.map((category) => {
            if (
                category?.parentCategoryId ===
                "00000000-0000-0000-0000-000000000000"
            ) {
                return {
                    label: ` > ${category.name}`,
                    value: null,
                    disabled: true,
                }
            } else {
                return {
                    label: ` >> ${category.name}`,
                    value: category.id,
                }
            }
        })
}
